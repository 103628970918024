import QrCodeEdit from "./QrCodeEdit";
import { useState, useEffect } from "react";
import { LineChart, Eye } from "lucide-react";
import QrCodeDisplay from "./QrCodeDisplay";
import { Link } from "react-router-dom";


function QRCard({ id, shortUrl, targetUrl, targetUrlAndroid, targetUrlIos, password, createdAt, campaignName, margin, patternColor1, patternColor2, patternType, backgroundColor, backgroundColorType, outerEyeColor1, outerEyeColor2, outerEyePattern, innerEyeColor1, innerEyeColor2, innerEyePattern, patternColorType, innerEyeColorType, outerEyeColorType, scans, onUpdateInfo, onUpdateQR, onDelete }) {

  const [currentCampaignName, setCurrentCampaignName] = useState(campaignName);
  const [currentTargetUrl, setCurrentTargetUrl] = useState(targetUrl);
  const [currentTargetUrlAndroid, setCurrentTargetUrlAndroid] = useState(targetUrlAndroid);
  const [currentTargetUrlIos, setCurrentTargetUrlIos] = useState(targetUrlIos);
  const [currentPassword, setCurrentPassword] = useState(password);
  const [iosAndroidTargeting, setIosAndroidTargeting] = useState(!!targetUrlIos);
  const [protectWithPassword, setProtectWithPassword] = useState(!!password);
  const [showPassword, setshowPassword] = useState(false);
  const [errorInfo, setErrorInfo] = useState(false);
  const [errorQR, setErrorQR] = useState(false);

  const [currentMargin, setCurrentMargin] = useState(margin);
  const [currentPatternColor1, setCurrentPatternColor1] = useState(patternColor1);
  const [currentPatternColor2, setCurrentPatternColor2] = useState(patternColor2);
  const [currentPatternType, setCurrentPatternType] = useState(patternType);
  const [currentBackgroundColor, setCurrentBackgroundColor] = useState(backgroundColor);
  const [currentBackgroundColorType, setCurrentBackgroundColorType] = useState(backgroundColorType);
  const [currentOuterEyeColor1, setCurrentOuterEyeColor1] = useState(outerEyeColor1);
  const [currentOuterEyeColor2, setCurrentOuterEyeColor2] = useState(outerEyeColor2);
  const [currentOuterEyePattern, setCurrentOuterEyePattern] = useState(outerEyePattern);
  const [currentInnerEyeColor1, setCurrentInnerEyeColor1] = useState(innerEyeColor1);
  const [currentInnerEyeColor2, setCurrentInnerEyeColor2] = useState(innerEyeColor2);
  const [currentInnerEyePattern, setCurrentInnerEyePattern] = useState(innerEyePattern);
  const [currentPatternColorType, setCurrentPatternColorType] = useState(patternColorType);
  const [currentInnerEyeColorType, setCurrentInnerEyeColorType] = useState(innerEyeColorType);
  const [currentOuterEyeColorType, setCurrentOuterEyeColorType] = useState(outerEyeColorType);

  useEffect(() => {
    setCurrentCampaignName(campaignName);
    setCurrentTargetUrl(targetUrl);
    setCurrentTargetUrlIos(targetUrlIos);
    setCurrentTargetUrlAndroid(targetUrlAndroid);
    setCurrentPassword(password);
    setIosAndroidTargeting(targetUrlIos && targetUrlAndroid);
    setProtectWithPassword(!!password);
    setErrorInfo(false);
    setCurrentMargin(margin);
    setCurrentPatternColor1(patternColor1);
    setCurrentPatternColor2(patternColor2);
    setCurrentPatternType(patternType);
    setCurrentBackgroundColor(backgroundColor);
    setCurrentBackgroundColorType(backgroundColorType);
    setCurrentOuterEyeColor1(outerEyeColor1);
    setCurrentOuterEyeColor2(outerEyeColor2);
    setCurrentOuterEyePattern(outerEyePattern);
    setCurrentInnerEyeColor1(innerEyeColor1);
    setCurrentInnerEyeColor2(innerEyeColor2);
    setCurrentInnerEyePattern(innerEyePattern);
    setCurrentPatternColorType(patternColorType);
    setCurrentInnerEyeColorType(innerEyeColorType);
    setCurrentOuterEyeColorType(outerEyeColorType);

  }, [campaignName, targetUrl, targetUrlAndroid, targetUrlIos, password, margin, patternColor1, patternColor2, patternType,
    backgroundColor, backgroundColorType, outerEyeColor1, outerEyeColor2, outerEyePattern,
    innerEyeColor1, innerEyeColor2, innerEyePattern, patternColorType, innerEyeColorType, outerEyeColorType
  ])

  const onNameChange = (e) => {
    setCurrentCampaignName(e.target.value);
  }

  const onTargetUrlChange = (e) => {
    setCurrentTargetUrl(e.target.value);
  }

  const onIosUrlChange = (e) => {
    setCurrentTargetUrlIos(e.target.value);
  }

  const onAndroidUrlChange = (e) => {
    setCurrentTargetUrlAndroid(e.target.value);
  }

  const onPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  }

  const onIosAndroidTargetingChange = (e) => {
    setIosAndroidTargeting(e.target.checked);
  }

  const onProtectWithPasswordChange = (e) => {
    setProtectWithPassword(e.target.checked);
  }

  const handleInfoUpdate = async (e) => {
    e.preventDefault();
    const res = await onUpdateInfo(id, currentCampaignName, currentTargetUrl, currentTargetUrlIos, currentTargetUrlAndroid, currentPassword, iosAndroidTargeting, protectWithPassword);
    if (!res) {
      setErrorInfo(true);
      return;
    }
    document.getElementById(`campaign_update_modal${id}`).close();
  }


  const onInfoUpdateModalClose = () => {
    setCurrentCampaignName(campaignName);
    setCurrentTargetUrl(targetUrl);
    setCurrentTargetUrlIos(targetUrlIos);
    setCurrentTargetUrlAndroid(targetUrlAndroid);
    setCurrentPassword(password);
    setIosAndroidTargeting(targetUrlIos && targetUrlAndroid);
    setProtectWithPassword(!!password);
    setshowPassword(false);
    setErrorInfo(false);
    document.getElementById(`campaign_update_modal${id}`).close();
  }

  const onQRUpdateModalClose = () => {
    console.log("onQRUpdateModalClose");
    setCurrentMargin(margin);
    setCurrentPatternColor1(patternColor1);
    setCurrentPatternColor2(patternColor2);
    setCurrentPatternType(patternType);
    setCurrentBackgroundColor(backgroundColor);
    setCurrentBackgroundColorType(backgroundColorType);
    setCurrentOuterEyeColor1(outerEyeColor1);
    setCurrentOuterEyeColor2(outerEyeColor2);
    setCurrentOuterEyePattern(outerEyePattern);
    setCurrentInnerEyeColor1(innerEyeColor1);
    setCurrentInnerEyeColor2(innerEyeColor2);
    setCurrentInnerEyePattern(innerEyePattern);
    setCurrentPatternColorType(patternColorType);
    setCurrentInnerEyeColorType(innerEyeColorType);
    setCurrentOuterEyeColorType(outerEyeColorType);
    setErrorQR(false);
    document.getElementById(`qr_update_modal${id}`).close();
  }


  const onCloseQRDisplay = () => {
    document.getElementById(`qr_update_modal${id}`).close();
    setErrorQR(false);
  }

  const onUpdateQRInfo = async (id, margin, patternColor1, patternColor2, patternType, backgroundColor, backgroundColorType, outerEyeColor1, outerEyeColor2, outerEyePattern, innerEyeColor1, innerEyeColor2, innerEyePattern, patternColorType, innerEyeColorType, outerEyeColorType) => {
    const res = await onUpdateQR(id, margin, patternColor1, patternColor2, patternType, backgroundColor, backgroundColorType, outerEyeColor1, outerEyeColor2, outerEyePattern, innerEyeColor1, innerEyeColor2, innerEyePattern, patternColorType, innerEyeColorType, outerEyeColorType);
    if (!res) {
      setErrorQR(true);
      return;
    }
    onCloseQRDisplay();
  }

  const handleDelete = async () => {
    await onDelete(id);
    document.getElementById(`campaign_delete_modal${id}`).close();
  }

  return (
    <>



      <div className="flex items-center justify-between bg-white border border-gray-200 rounded-lg shadow   hover:bg-gray-100  ">

        <div className="flex items-center ml-2 md:mr-5 ">
          <div className="tooltip rounderd-lg" data-tip="Edit QR Code" onClick={() => document.getElementById(`qr_update_modal${id}`).showModal()} >
            {/* <QrCodeImage key={id} className="text-default-400 cursor-pointer" size={50} onClick={() => document.getElementById(`qr_update_modal${id}`).showModal()} /> */}
            <QrCodeDisplay key={id}
              url={shortUrl} margin={0} backgroundColor={currentBackgroundColor} extension='canvas' size={80}
              patternType={currentPatternType} patternColorType={currentPatternColorType} patternColor1={currentPatternColor1} patternColor2={currentPatternColor2}
              outerEyePattern={currentOuterEyePattern} outerEyeColorType={currentOuterEyeColorType} outerEyeColor1={currentOuterEyeColor1} outerEyeColor2={currentOuterEyeColor2}
              innerEyePattern={currentInnerEyePattern} innerEyeColorType={currentInnerEyeColorType} innerEyeColor1={currentInnerEyeColor1} innerEyeColor2={currentInnerEyeColor2}

            />
          </div>


          <div className="flex flex-col justify-between p-4 leading-normal ml-3 ">
            <h5 className="mb-1 lg:text-lg text-md font-bold tracking-tight text-gray-90">{campaignName}</h5>
            <p className="text-sm md:text-md  mb-1 font-normal text-gray-700">{targetUrl}</p>
            <p className="text-sm md:text-md mb-1 font-normal text-gray-700">{createdAt}</p>
          </div>

        </div>

        <div className=" mr-2 md:mr-5 flex items-center gap-3">
          <div className="bg-gray-200 md:px-3 md:py-1  px-1 py-1 rounded-lg hidden sm:block">
            <Link to={`analytics/${id}`} className="flex flex-column gap-3 justify-center items-center lg:text-lg">
              <LineChart key={id} size={15} />
              {scans} scans

            </Link>

          </div>

          <div>
            <div className="dropdown dropdown-end">
              <div tabIndex={0} role="button" className="">
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
                  <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                </svg>
              </div>
              <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52  border-2">
                <li>
                  <button className="w-full text-left px-4 py-2 hover:bg-gray-100" onClick={() => document.getElementById(`campaign_update_modal${id}`).showModal()}>Edit</button>
                </li>
                <li className="sm:hidden">
                  {/* <a href="#" className="">Analytics</a> */}
                  <Link to={`analytics/${id}`} className="block px-4 py-2 hover:bg-gray-100 ">
                    Analytics

                  </Link>

                </li>


                <div className="py-2">

                  <button href="#" className=" w-full text-left px-4 py-2 text-sm text-red-700 hover:bg-gray-100  border-t-2" onClick={() => document.getElementById(`campaign_delete_modal${id}`).showModal()}>Delete</button>
                </div>
              </ul>
            </div>




          </div>
        </div>

      </div>


      <dialog id={`qr_update_modal${id}`} className="modal" onCancel={onQRUpdateModalClose} >
        <div className="modal-box max-w-2xl">
          <h3 className="font-bold text-lg">Edit QR Code</h3>

          {errorQR &&
            <div role="alert" className="alert alert-error mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
              <span>Some thing wrong happend while updating the QR Code</span>
            </div>
          }
          <div className="">
            <QrCodeEdit key={id}
              id={id} url={shortUrl} currentMargin={currentMargin} currentPatternColor1={currentPatternColor1} currentPatternColor2={currentPatternColor2} currentPatternType={currentPatternType} currentBackgroundColor={currentBackgroundColor} currentBackgroundColorType={currentBackgroundColorType} currentOuterEyeColor1={currentOuterEyeColor1} currentOuterEyeColor2={currentOuterEyeColor2} currentOuterEyePattern={currentOuterEyePattern} currentInnerEyeColor1={currentInnerEyeColor1} currentInnerEyeColor2={currentInnerEyeColor2} currentInnerEyePattern={currentInnerEyePattern} currentPatternColorType={currentPatternColorType} currentInnerEyeColorType={currentInnerEyeColorType} currentOuterEyeColorType={currentOuterEyeColorType}
              onSave={onUpdateQRInfo} onClose={onCloseQRDisplay}
            />

          </div>
        </div>
      </dialog>


      <dialog id={`campaign_update_modal${id}`} className="modal" onCancel={onInfoUpdateModalClose} >
        <div className="modal-box max-w-2xl">
          {errorInfo &&
            <div role="alert" className="alert alert-error mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
              <span>Some thing wrong happend while updating the QR Code</span>
            </div>
          }

          <div >
            <form className=" space-y-3 " onSubmit={handleInfoUpdate} >



              <label className="input input-bordered flex items-center gap-2">
                Name
                <input type="text" className="grow" onChange={onNameChange} value={currentCampaignName} required />
              </label>

              <label className="input input-bordered flex items-center gap-2">
                Target URL
                <input type="url" className="grow" onChange={onTargetUrlChange} value={currentTargetUrl} required placeholder="https://www.example.com" />
              </label>


              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text">IOS/Android Targeting</span>

                  <input type="checkbox" className="toggle" checked={iosAndroidTargeting} onChange={onIosAndroidTargetingChange} />
                </label>
              </div>

              {iosAndroidTargeting && (
                <div className="space-y-2">
                  <label className="input input-bordered flex items-center gap-2">
                    IOS URL
                    <input type="url" className="grow" onChange={onIosUrlChange} value={currentTargetUrlIos} required placeholder="https://www.example.com" />
                  </label>
                  <label className="input input-bordered flex items-center gap-2">
                    Android URL
                    <input type="url" className="grow" onChange={onAndroidUrlChange} value={currentTargetUrlAndroid} required placeholder="https://www.example.com" />
                  </label>
                </div>
              )}

              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text">Protect With Password</span>

                  <input type="checkbox" className="toggle" checked={protectWithPassword} onChange={onProtectWithPasswordChange} />

                </label>
              </div>

              {protectWithPassword && (
                <label className="input input-bordered flex items-center gap-2">
                  Password
                  <input type={showPassword ? "text" : "password"} className="grow" onChange={onPasswordChange} value={currentPassword} required />
                  <Eye onClick={() => setshowPassword((showPassword) => !showPassword)} />
                </label>
              )}

              <div className="flex justify-around mt-6">

                <button className="btn  bg-sky-600 text-white px-6" onClick={handleInfoUpdate}>Update</button>

                <button className="btn" onClick={onInfoUpdateModalClose}>Cancel</button>

              </div>

            </form>
          </div>

        </div>
      </dialog>


      <dialog id={`campaign_delete_modal${id}`} className="modal"  >
        <div className="modal-box max-w-2xl">
          <div className="flex items-center gap-4 mb-8 justify-center">

            <div role="alert" className="alert alert-warning inline-block   w-14">
              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
            </div>
            <span>Are you sure you want to delete this QR!</span>
          </div>

          <div className="flex justify-around">
            <button className="btn text-red-500" onClick={handleDelete} >Yes, Delete it!</button>
            <button className="btn" onClick={() => document.getElementById(`campaign_delete_modal${id}`).close()}>No</button>
          </div>

        </div>
      </dialog>
    </>
  );
}

export default QRCard;