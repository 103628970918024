import  {  useEffect, useState } from "react";
import QRCodeStyling from "qr-code-styling";

function QrCodeDownload({
    id,
    url,
    margin,
    patternType,
    patternColorType,
    patternColor1,
    patternColor2,
    outerEyePattern,
    outerEyeColorType,
    outerEyeColor1,
    outerEyeColor2,
    innerEyePattern,
    innerEyeColorType,
    innerEyeColor1,
    innerEyeColor2,
    backgroundColor,
    logo, 
    logoMargin,
    onClose
}) {

    const [downloadSize, setDonwloadSize] = useState(1);
    const [downloadExt, setDonwloadExt] = useState('png');
    const [qrCode, setQrCode] = useState(null);

    const sizes = {
        0:256,
        1: 512,
        2: 1024,
        3: 2048,
        4: 2*2048
    }

    const onDownloadSizeChange = (event) => {
        setDonwloadSize(event.target.value)
    }

    const onDownloadExtChange = (event) => {
        setDonwloadExt(event.target.value)
    }

    const downloadQR = () => {
        qrCode.download({ name: "qr", extension: downloadExt });
    }
    useEffect(() => {
        const initialOptions = {
            width: sizes[downloadSize],
            height: sizes[downloadSize],
            type: 'canvas',
            data: url,
            image: logo ? URL.createObjectURL(logo) : null,
            margin: margin,
            dotsOptions: {
                color: patternColor1,
                type: patternType,
            },
            backgroundOptions: {
                color: backgroundColor,
            },
            cornersSquareOptions: {
                color: outerEyeColor1,
                type: outerEyePattern,
            },
            cornersDotOptions: {
                color: innerEyeColor1,
                type: innerEyePattern,
            },
            imageOptions: {
                crossOrigin: "anonymous",
                margin: logoMargin
              }
        };

        if (patternColorType === 'gradient') {
            initialOptions.dotsOptions.gradient = {
                type: 'linear', // 'radial'
                rotation: 0,
                colorStops: [{ offset: 0, color: patternColor1 }, { offset: 1, color: patternColor2 }]
            };
        }

        if (innerEyeColorType === 'gradient') {
            initialOptions.cornersDotOptions.gradient = {
                type: 'linear', // 'radial'
                rotation: 0,
                colorStops: [{ offset: 0, color: innerEyeColor1 }, { offset: 1, color: innerEyeColor2 }]
            };
        }

        if (outerEyeColorType === 'gradient') {
            initialOptions.cornersSquareOptions.gradient = {
                type: 'linear', // 'radial'
                rotation: 0,
                colorStops: [{ offset: 0, color: outerEyeColor1 }, { offset: 1, color: outerEyeColor2 }]
            };
        }

        setQrCode(new QRCodeStyling(initialOptions));

    }, []);

    useEffect(() => {

        const initialOptions = {
            width: sizes[downloadSize],
            height: sizes[downloadSize],
            type: 'canvas',
            data: url,
            image: logo ? URL.createObjectURL(logo) : null,
            margin: margin,
            dotsOptions: {
                color: patternColor1,
                type: patternType,
            },
            backgroundOptions: {
                color: backgroundColor,
            },
            cornersSquareOptions: {
                color: outerEyeColor1,
                type: outerEyePattern,
            },
            cornersDotOptions: {
                color: innerEyeColor1,
                type: innerEyePattern,
            },
            imageOptions: {
                crossOrigin: "anonymous",
                margin: logoMargin
              }
        };

        if (patternColorType === 'gradient') {
            initialOptions.dotsOptions.gradient = {
                type: 'linear', // 'radial'
                rotation: 0,
                colorStops: [{ offset: 0, color: patternColor1 }, { offset: 1, color: patternColor2 }]
            };
        }

        if (innerEyeColorType === 'gradient') {
            initialOptions.cornersDotOptions.gradient = {
                type: 'linear', // 'radial'
                rotation: 0,
                colorStops: [{ offset: 0, color: innerEyeColor1 }, { offset: 1, color: innerEyeColor2 }]
            };
        }

        if (outerEyeColorType === 'gradient') {
            initialOptions.cornersSquareOptions.gradient = {
                type: 'linear', // 'radial'
                rotation: 0,
                colorStops: [{ offset: 0, color: outerEyeColor1 }, { offset: 1, color: outerEyeColor2 }]
            };
        }

        setQrCode(new QRCodeStyling(initialOptions));

    }, [
        url,
        margin,
        patternType,
        patternColorType,
        patternColor1,
        patternColor2,
        outerEyePattern,
        outerEyeColorType,
        outerEyeColor1,
        outerEyeColor2,
        innerEyePattern,
        innerEyeColorType,
        innerEyeColor1,
        innerEyeColor2,
        backgroundColor,
        downloadSize,
        logo,
        logoMargin
    ]);

    return (
        <div className="modal-box max-w-2xl">
                    <h3 className="font-bold text-lg mb-5 ">Download QR Code</h3>

                    <div className="mb-3">

                        <div className="flex gap-5 justify-center">

                            <div className="form-control ">
                                <label className="label cursor-pointer flex gap-2">
                                    <span className="label-text">PNG</span>
                                    <input type="radio"  name={`download-ext-${id}`} className="radio checked:bg-blue-500" checked={downloadExt==='png'} value="png" onChange={onDownloadExtChange} />
                                </label>
                            </div>
                            <div className="form-control ">
                                <label className="label cursor-pointer flex gap-2">
                                    <span className="label-text">SVG</span>
                                    <input type="radio" name={`download-ext-${id}`} className="radio checked:bg-red-500" checked={downloadExt==='svg'} value="svg" onChange={onDownloadExtChange} />
                                </label>
                            </div>

                        </div>
                    </div>
                    <div className="mb-5">

                        <h4 className="text-black text-md mb-1 ">QR Size</h4>
                        <input type="range" min={0} max={4} className="range" step={1} value={downloadSize} onChange={onDownloadSizeChange} />
                        <div className="w-full flex justify-between text-xs px-2" >
                            <span>256px</span>
                            <span>512px</span>
                            <span>1024px</span>
                            <span>2048px</span>
                            <span>4k</span>
                        </div>
                    </div>
                    <div className="flex justify-around">

                    <button className="btn  bg-sky-600 text-white px-6" onClick={downloadQR}>Download</button>
                    <button className="btn" onClick={onClose}>Close</button>
                    </div>
        </div>
    )
}

export default QrCodeDownload;