
import Chart from "react-apexcharts";
// import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from "./AxiosConfig";
function Analytics() {

    let { campaignId } = useParams();

    const [totalScans, setTotalScans] = useState(0);
    const [scanPerCountry, setScanPerCountry] = useState({ options: {}, series: [] });
    const [scanPerDevice, setScanPerDevice] = useState({ options: {}, series: [] });
    const [scanPerOS, setScanPerOS] = useState({ options: {}, series: [] });
    const [scanPerBrowser, setScanPerBrowser] = useState({ options: {}, series: [] });
    const [scansPerDate, setScansPerDate] = useState({ options: {}, series: [] });


    const getData = async () => {
        const response = await axios.get(`campaigns/${campaignId}/analytics/`);
        console.log(response.data);
        setTotalScans(response.data.total_scans);

        setScansPerDate(
            {
                options: {
                    chart: {
                        id: "basic-bar"
                    },
                    xaxis: {
                        categories: response.data.scans_per_date.labels
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                },
                series: [
                    {
                        name: "Scans",
                        data: response.data.scans_per_date.data
                    }
                ]
            }
        )

        setScanPerCountry(
            {
                options: {

                    chart: {
                        type: 'bar',
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            borderRadiusApplication: 'end',
                            horizontal: true,
                            barHeight: 30

                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: response.data.scans_per_country.labels,
                    }
                },
                series: [
                    {
                        name: "Scans",
                        data: response.data.scans_per_country.data
                    }
                ]
            }
        );

        setScanPerDevice(
            {
                options: {
                    chart: {
                        type: 'bar',
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            borderRadiusApplication: 'end',
                            horizontal: true,
                            barHeight: 30
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: response.data.scans_per_device.labels,
                    }
                },
                series: [
                    {
                        name: "Scans",
                        data: response.data.scans_per_device.data
                    }
                ]
            }
        );

        setScanPerOS(
            {
                options: {
                    chart: {
                        type: 'bar',
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            borderRadiusApplication: 'end',
                            horizontal: true,
                            barHeight: 30
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: response.data.scans_per_os.labels,
                    }
                },
                series: [
                    {
                        name: "Scans",
                        data: response.data.scans_per_os.data
                    }
                ]
            }
        );

        setScanPerBrowser(
            {
                options: {
                    chart: {
                        type: 'bar',
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            borderRadiusApplication: 'end',
                            horizontal: true,
                            barHeight: 30
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: response.data.scans_per_browser.labels,
                    }
                },
                series: [
                    {
                        name: "Scans",
                        data: response.data.scans_per_browser.data
                    }
                ]
            }
        );

    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <div className=" ">

            <h1 className="text-center text-xl font-bold mx-auto">
                {totalScans} Scans
            </h1>

            <div className="mt-16 mb-5 mx-auto flex flex-wrap max-w-7xl justify-center gap-10 ">

                <div className="card bg-base-100 shadow-xl sm:max-w-sm  justify-center   mx-auto   lg:hidden">

                    <div className="card-body">
                        <h2 className="card-title">By Date</h2>
                        <Chart
                            options={scansPerDate.options}
                            series={scansPerDate.series}
                            type="line"
                            width="300"
                        />
                    </div>
                </div>

                <div className="card bg-base-100 shadow-xl max-w-3xl  mx-auto hidden lg:block">

                    <div className="card-body">
                        <h2 className="card-title">By Date</h2>
                        <Chart
                            options={scansPerDate.options}
                            series={scansPerDate.series}
                            type="line"
                            width="600"
                        />
                    </div>
                </div>
            </div>


            <div className="mt-16 mb-5 mx-auto flex flex-wrap max-w-7xl justify-center gap-10 ">

                <div className="card bg-base-100 shadow-xl sm:max-w-sm    lg:hidden ">
                    <div className="card-body">
                        <h2 className="card-title">By Browser</h2>
                        <Chart
                            options={scanPerBrowser.options}
                            series={scanPerBrowser.series}
                            type="bar"
                            width="300"
                            className=""
                        />
                    </div>
                </div>

                <div className="card bg-base-100 shadow-xl max-w-3xl  mx-auto hidden lg:block ">
                    <div className="card-body">
                        <h2 className="card-title">By Browser</h2>
                        <Chart
                            options={scanPerBrowser.options}
                            series={scanPerBrowser.series}
                            type="bar"
                            width="500"
                            className=""
                        />
                    </div>
                </div>


                <div className="card bg-base-100 shadow-xl sm:max-w-sm    lg:hidden ">
                    <div className="card-body">
                        <h2 className="card-title">By Device</h2>
                        <Chart
                            options={scanPerDevice.options}
                            series={scanPerDevice.series}
                            type="bar"
                            width="300"
                            className=""
                        />
                    </div>
                </div>

                <div className="card bg-base-100 shadow-xl max-w-3xl  mx-auto hidden lg:block ">
                    <div className="card-body">
                        <h2 className="card-title">By Device</h2>
                        <Chart
                            options={scanPerDevice.options}
                            series={scanPerDevice.series}
                            type="bar"
                            width="500"
                            className=""
                        />
                    </div>
                </div>


                <div className="card bg-base-100 shadow-xl sm:max-w-sm    lg:hidden ">
                    <div className="card-body">
                        <h2 className="card-title">By OS</h2>
                        <Chart
                            options={scanPerOS.options}
                            series={scanPerOS.series}
                            type="bar"
                            width="300"
                            className=""
                        />
                    </div>
                </div>

                <div className="card bg-base-100 shadow-xl max-w-3xl  mx-auto hidden lg:block ">
                    <div className="card-body">
                        <h2 className="card-title">By OS</h2>
                        <Chart
                            options={scanPerOS.options}
                            series={scanPerOS.series}
                            type="bar"
                            width="500"
                            className=""
                        />
                    </div>
                </div>


                <div className="card bg-base-100 shadow-xl sm:max-w-sm    lg:hidden ">
                    <div className="card-body">
                        <h2 className="card-title">By Country</h2>
                        <Chart
                            options={scanPerCountry.options}
                            series={scanPerCountry.series}
                            type="bar"
                            width="300"
                            className=""
                        />
                    </div>
                </div>

                <div className="card bg-base-100 shadow-xl max-w-3xl  mx-auto hidden lg:block ">
                    <div className="card-body">
                        <h2 className="card-title">By Country</h2>
                        <Chart
                            options={scanPerCountry.options}
                            series={scanPerCountry.series}
                            type="bar"
                            width="500"
                            className=""
                        />
                    </div>
                </div>

            </div>
        </div>
        // <div>
        //     hello
        // </div>
    );

}

export default Analytics;





