// src/utils/axiosConfig.js
import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://backend.fastQR.link',
});


instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('fastQRtoken');
      
    if (token) {
        config.headers.Authorization = `${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});


instance.interceptors.response.use(
    
    response => {
        return response; // Return normal response for other cases
    },
    error => {
        
        if (error.response && error.response.status === 401) {

            localStorage.removeItem("fastQRtoken");
            window.location.href = '/login';
            return;
        }
        return Promise.reject(error);
    }
);

export default instance;





