import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import './index.css'
import ErrorPage from "./Error404.js";
import Analytics from "./Analytics.js";
import Dashboard from "./Dashboard.js";
import Login from "./Login.js";
import PrivateRoute from "./PrivateRoute.js";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <PrivateRoute> <Dashboard /> </PrivateRoute>,
            },
            {
                path: "analytics/:campaignId",
                element: <PrivateRoute> <Analytics /> </PrivateRoute>,
            },
            {
                path: "login",
                element: <Login />,
            },
        ],
    },
]);

const el = document.getElementById("root");
const root = ReactDOM.createRoot(el);


root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);