import React, { useState, useEffect } from "react";
import QrCodeDisplay from "./QrCodeDisplay";
import QrCodeDownload from "./QrCodeDownload";


function QrCodeEdit({ url, id, currentMargin, currentPatternType, currentPatternColorType, currentPatternColor1, currentPatternColor2, currentOuterEyePattern, currentOuterEyeColorType, currentOuterEyeColor1, currentOuterEyeColor2, currentInnerEyePattern, currentInnerEyeColorType, currentInnerEyeColor1, currentInnerEyeColor2, currentBackgroundColor, currentBackgroundColorType, onClose, onSave }) {

    const [margin, setmargin] = useState(currentMargin);
    const [backgroundColor, setbackgroundColor] = useState(currentBackgroundColor);
    const [backgroundColorType, setBackgroundColorType] = useState(currentBackgroundColorType);
    const [patternType, setpatternType] = useState(currentPatternType);
    const [patternColorType, setpatternColorType] = useState(currentPatternColorType);
    const [patternColor1, setpatternColor1] = useState(currentPatternColor1);
    const [patternColor2, setpatternColor2] = useState(currentPatternColor2);
    const [outerEyePattern, setouterEyePattern] = useState(currentOuterEyePattern);
    const [outerEyeColorType, setouterEyeColorType] = useState(currentOuterEyeColorType);
    const [outerEyeColor1, setouterEyeColor1] = useState(currentOuterEyeColor1);
    const [outerEyeColor2, setouterEyeColor2] = useState(currentOuterEyeColor2);
    const [innerEyePattern, setinnerEyePattern] = useState(currentInnerEyePattern);
    const [innerEyeColorType, setinnerEyeColorType] = useState(currentInnerEyeColorType);
    const [innerEyeColor1, setinnerEyeColor1] = useState(currentInnerEyeColor1);
    const [innerEyeColor2, setinnerEyeColor2] = useState(currentInnerEyeColor2);
    const [logoMargin, setLogoMargin] = useState(0);
    const [logo, setLogo] = useState(null);
    const [tab, setTab] = useState(null);

    const onTabChange = (index) => {
        if (tab === index) {
            setTab(null);
            return;
        }
        setTab(index);
    }



    useEffect(() => {
        setmargin(currentMargin);
        setbackgroundColor(currentBackgroundColor);
        setBackgroundColorType(currentBackgroundColorType);
        setpatternType(currentPatternType);
        setpatternColorType(currentPatternColorType);
        setpatternColor1(currentPatternColor1);
        setpatternColor2(currentPatternColor2);
        setouterEyePattern(currentOuterEyePattern);
        setouterEyeColorType(currentOuterEyeColorType);
        setouterEyeColor1(currentOuterEyeColor1);
        setouterEyeColor2(currentOuterEyeColor2);
        setinnerEyePattern(currentInnerEyePattern);
        setinnerEyeColorType(currentInnerEyeColorType);
        setinnerEyeColor1(currentInnerEyeColor1);
        setinnerEyeColor2(currentInnerEyeColor2);

    }, [currentMargin, currentBackgroundColor, currentBackgroundColorType, currentPatternType, currentPatternColorType, currentPatternColor1,
        currentPatternColor2, currentOuterEyePattern, currentOuterEyeColorType, currentOuterEyeColor1, currentOuterEyeColor2,
        currentInnerEyePattern, currentInnerEyeColorType, currentInnerEyeColor1, currentInnerEyeColor2,
    ])

    const onLogoMarginChange = (event) => {
        setLogoMargin(event.target.value);
    }

    const onLogoChange = (event) => {
        setLogo(event.target.files[0]);
    }

    const onMarginChange = (event) => {
        setmargin(event.target.value);
    }

    const onBackgroundColorChange = (event) => {
        setbackgroundColor(event.target.value);
    }
    const onBackgroundColorTypeChange = (event) => {
        setBackgroundColorType(event.target.value);
        if (event.target.value === 'transparent') {
            setbackgroundColor("transparent");
        } else {
            setbackgroundColor("#FFFFFF");
        }
    }

    const onPatternTypeChange = (event) => {
        setpatternType(event.target.value);

    }

    const onPatternColorTypeChange = (event) => {
        setpatternColorType(event.target.value);

    }

    const onPatternColor1Change = (event) => {
        setpatternColor1(event.target.value);
    }

    const onPatternColor2Change = (event) => {
        setpatternColor2(event.target.value);
    }

    const onOuterEyeTypeChange = (event) => {
        setouterEyePattern(event.target.value);
    }

    const onOuterEyeColorTypeChange = (event) => {
        setouterEyeColorType(event.target.value);
    }

    const onOuterEyeColor1Change = (event) => {
        setouterEyeColor1(event.target.value);
    }

    const onOuterEyeColor2Change = (event) => {
        setouterEyeColor2(event.target.value);
    }


    const onInnerEyeTypeChange = (event) => {
        setinnerEyePattern(event.target.value);
    }

    const onInnerEyeColorTypeChange = (event) => {
        setinnerEyeColorType(event.target.value);
    }

    const onInnerEyeColor1Change = (event) => {
        setinnerEyeColor1(event.target.value);
    }

    const onInnerEyeColor2Change = (event) => {
        setinnerEyeColor2(event.target.value);
    }

    const saveHandler = () => {
        onSave(id, margin, patternColor1, patternColor2, patternType, backgroundColor, backgroundColorType, outerEyeColor1, outerEyeColor2, outerEyePattern, innerEyeColor1, innerEyeColor2, innerEyePattern, patternColorType, innerEyeColorType, outerEyeColorType);
    }

    const closeDownloadHandler = () => {
        document.getElementById(`qr_download_modal${id}`).close();
    }
    const closeHandler = () => {
        // return the values to the initial values
        setbackgroundColor(currentBackgroundColor);
        setBackgroundColorType(currentBackgroundColorType);
        setpatternType(currentPatternType);
        setpatternColorType(currentPatternColorType);
        setpatternColor1(currentPatternColor1);
        setpatternColor2(currentPatternColor2);
        setouterEyePattern(currentOuterEyePattern);
        setouterEyeColorType(currentOuterEyeColorType);
        setouterEyeColor1(currentOuterEyeColor1);
        setouterEyeColor2(currentOuterEyeColor2);
        setinnerEyePattern(currentInnerEyePattern);
        setinnerEyeColorType(currentInnerEyeColorType);
        setinnerEyeColor1(currentInnerEyeColor1);
        setinnerEyeColor2(currentInnerEyeColor2);
        setmargin(currentMargin);

        onClose();
    }

    return (
        <div className="">
            <div className="flex justify-center mb-3">
                <QrCodeDisplay key={id}
                    url={url} margin={margin} backgroundColor={backgroundColor} extension='canvas' size={300}
                    patternType={patternType} patternColorType={patternColorType} patternColor1={patternColor1} patternColor2={patternColor2}
                    outerEyePattern={outerEyePattern} outerEyeColorType={outerEyeColorType} outerEyeColor1={outerEyeColor1} outerEyeColor2={outerEyeColor2}
                    innerEyePattern={innerEyePattern} innerEyeColorType={innerEyeColorType} innerEyeColor1={innerEyeColor1} innerEyeColor2={innerEyeColor2}
                    logo={logo} logoMargin={logoMargin}
                />
            </div>

            <div className="flex justify-center mb-5">

                <button className="btn bg-sky-600 text-white  text-left px-4 py-2 " onClick={() => document.getElementById(`qr_download_modal${id}`).showModal()}>Download</button>
            </div>

            <dialog id={`qr_download_modal${id}`} className="modal" >
                <QrCodeDownload id={id} url={url} margin={margin} backgroundColor={backgroundColor} onClose={closeDownloadHandler}
                    patternType={patternType} patternColorType={patternColorType} patternColor1={patternColor1} patternColor2={patternColor2}
                    outerEyePattern={outerEyePattern} outerEyeColorType={outerEyeColorType} outerEyeColor1={outerEyeColor1} outerEyeColor2={outerEyeColor2}
                    innerEyePattern={innerEyePattern} innerEyeColorType={innerEyeColorType} innerEyeColor1={innerEyeColor1} innerEyeColor2={innerEyeColor2}
                    logo={logo} logoMargin={logoMargin}    
                />
            </dialog>



            <label className="input input-bordered flex items-center gap-2  ">
                Margin
                <input type="number" className="grow " min={0} max={100} value={margin} onChange={onMarginChange} />
            </label>



            <div className="space-y-5 mt-6 ">

                <div className="collapse collapse-arrow border">
                    <input type="radio" name={`my-accordion-${id}`} checked={tab === 0} onClick={() => onTabChange(0)} />
                    <div className="collapse-title text-xl font-medium">
                        Background options
                    </div>
                    <div className="collapse-content">

                        <div>
                            <div className="flex gap-5">
                                <div className="form-control ">
                                    <label className="label cursor-pointer flex gap-2 ">

                                        <span className="label-text">Transparent</span>
                                        {
                                            backgroundColorType === 'transparent' && <input type="radio" name={`backgroundColorType${id}`} className="radio " value="transparent" checked onChange={onBackgroundColorTypeChange} />
                                        }
                                        {
                                            backgroundColorType !== 'transparent' && <input type="radio" name={`backgroundColorType${id}`} className="radio " value="transparent" onChange={onBackgroundColorTypeChange} />
                                        }
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label cursor-pointer flex gap-2">
                                        <span className="label-text">Colored</span>
                                        {
                                            backgroundColorType === 'colored' && <input type="radio" name={`backgroundColorType${id}`} className="radio " value="colored" checked onChange={onBackgroundColorTypeChange} />
                                        }
                                        {
                                            backgroundColorType !== 'colored' && <input type="radio" name={`backgroundColorType${id}`} className="radio " value="colored" onChange={onBackgroundColorTypeChange} />
                                        }
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className="flex gap-4">
                            {
                                backgroundColorType === 'colored' &&
                                <label className="input input-bordered flex items-center gap-2">
                                    Color
                                    <input type="color" className="grow" value={backgroundColor} onChange={onBackgroundColorChange} />
                                </label>
                            }
                        </div>
                    </div>
                </div>

                <div className="collapse collapse-arrow border">
                    <input type="radio" name={`my-accordion-${id}`} checked={tab === 6} onClick={() => onTabChange(6)} />
                    <div className="collapse-title text-xl font-medium">
                        Logo options
                    </div>
                    <div className="collapse-content">
                        <div className="mb-3">

                        <span className="text-gray-500 ">* We don't save your logo, so it'll be removed when you refresh. But you can add one and download the QR code with it</span>
                        </div>
                        <div>
                            <label className="input input-bordered flex items-center gap-2  ">
                                Margin
                                <input type="number" className="grow " min={0} max={100} value={logoMargin} onChange={onLogoMarginChange} />
                            </label>
                        </div>


                        <label className="form-control w-full mt-2 ">
                            
                            <input type="file" className="file-input file-input-bordered w-full " onChange={onLogoChange} />
                            
                        </label>
                    </div>
                </div>


                <div className="collapse collapse-arrow border">
                    <input type="radio" name={`my-accordion-${id}`} checked={tab === 1} onClick={() => onTabChange(1)} />
                    <div className="collapse-title text-xl font-medium">
                        Dots Options
                    </div>
                    <div className="collapse-content">
                        <label className="input  flex items-center  gap-2 mb-3  outline-none  border-none ring-0">


                            Style

                            <select className="select select-bordered grow focus:outline-none" value={patternType} onChange={onPatternTypeChange}>
                                <option value="dots">Dot</option>
                                <option value="square">Square</option>
                                <option value="rounded">Rounded</option>
                                <option value="extra-rounded">Extra Rounded</option>
                                <option value="classy">Classy</option>
                                <option value="classy-rounded">Classy Rounded</option>
                            </select>

                        </label>

                        <div>
                            <h2>Color Type</h2>
                            <div className="flex gap-5">
                                <div className="form-control ">
                                    <label className="label cursor-pointer flex gap-2 ">
                                        <span className="label-text">Single</span>
                                        {
                                            patternColorType === 'single' && <input type="radio" name={`patternColorType${id}`} className="radio " value="single" checked onChange={onPatternColorTypeChange} />
                                        }
                                        {
                                            patternColorType !== 'single' && <input type="radio" name={`patternColorType${id}`} className="radio " value="single" onChange={onPatternColorTypeChange} />
                                        }
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label cursor-pointer flex gap-2">
                                        <span className="label-text">Gradient</span>
                                        {
                                            patternColorType === 'gradient' && <input type="radio" name={`patternColorType${id}`} className="radio " value="gradient" checked onChange={onPatternColorTypeChange} />
                                        }
                                        {
                                            patternColorType !== 'gradient' && <input type="radio" name={`patternColorType${id}`} className="radio " value="gradient" onChange={onPatternColorTypeChange} />
                                        }
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className="flex gap-4">
                            <label className="input input-bordered flex items-center gap-2">
                                Color 1
                                <input type="color" className="grow" value={patternColor1} onChange={onPatternColor1Change} />
                            </label>
                            {
                                patternColorType === 'gradient' &&
                                <label className="input input-bordered flex items-center gap-2">
                                    Color 2
                                    <input type="color" className="grow" value={patternColor2} onChange={onPatternColor2Change} />
                                </label>
                            }
                        </div>
                    </div>
                </div>


                <div className="collapse collapse-arrow border">
                    <input type="radio" name={`my-accordion-${id}`} checked={tab === 3} onClick={() => onTabChange(3)} />
                    <div className="collapse-title text-xl font-medium">
                        Outer Eye Options
                    </div>
                    <div className="collapse-content">
                        <label className="input flex items-center gap-2 mb-3">
                            Style
                            <select className="select select-bordered grow" value={outerEyePattern} onChange={onOuterEyeTypeChange}>
                                <option value="dots">Dot</option>
                                <option value="square">Square</option>
                                <option value="extra-rounded">Extra Rounded</option>

                            </select>

                        </ label>

                        <div>
                            <h2>Color Type</h2>
                            <div className="flex gap-5">
                                <div className="form-control">
                                    <label className="label cursor-pointer flex gap-2">
                                        <span className="label-text">Single</span>
                                        {
                                            outerEyeColorType === 'single' && <input type="radio" name={`outerEyeColorType${id}`} className="radio " value="single" checked onChange={onOuterEyeColorTypeChange} />
                                        }
                                        {
                                            outerEyeColorType !== 'single' && <input type="radio" nname={`outerEyeColorType${id}`} className="radio " value="single" onChange={onOuterEyeColorTypeChange} />
                                        }
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label cursor-pointer flex gap-2">
                                        <span className="label-text">Gradient</span>
                                        {
                                            outerEyeColorType === 'gradient' && <input type="radio" name={`outerEyeColorType${id}`} className="radio " value="gradient" checked onChange={onOuterEyeColorTypeChange} />
                                        }
                                        {
                                            outerEyeColorType !== 'gradient' && <input type="radio" name={`outerEyeColorType${id}`} className="radio " value="gradient" onChange={onOuterEyeColorTypeChange} />
                                        }
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className="flex gap-4">
                            <label className="input input-bordered flex items-center gap-2">
                                Color 1
                                <input type="color" className="grow" value={outerEyeColor1} onChange={onOuterEyeColor1Change} />
                            </label>
                            {
                                outerEyeColorType === 'gradient' &&
                                <label className="input input-bordered flex items-center gap-2">
                                    Color 2
                                    <input type="color" className="grow" value={outerEyeColor2} onChange={onOuterEyeColor2Change} />
                                </label>
                            }


                        </div>
                    </div>
                </div>


                <div className="collapse collapse-arrow  border">
                    <input type="radio" name={`my-accordion-${id}`} checked={tab === 4} onClick={() => onTabChange(4)} />
                    <div className="collapse-title text-xl font-medium">
                        Inner Eye Options
                    </div>
                    <div className="collapse-content">
                        <label className="input  flex items-center gap-2 mb-3">
                            Style
                            <select className="select select-bordered grow" value={innerEyePattern} onChange={onInnerEyeTypeChange}>
                                <option value="dots">Dot</option>
                                <option value="square">Square</option>

                            </select>

                        </ label>

                        <div>
                            <h2>Color Type</h2>
                            <div className="flex gap-5">
                                <div className="form-control">
                                    <label className="label cursor-pointer flex gap-2">
                                        <span className="label-text">Single</span>
                                        {
                                            innerEyeColorType === 'single' && <input type="radio" name={`innerEyeColorType${id}`} className="radio " value="single" checked onChange={onInnerEyeColorTypeChange} />
                                        }
                                        {
                                            innerEyeColorType !== 'single' && <input type="radio" name={`innerEyeColorType${id}`} className="radio " value="single" onChange={onInnerEyeColorTypeChange} />
                                        }
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label cursor-pointer flex gap-2">
                                        <span className="label-text">Gradient</span>
                                        {
                                            innerEyeColorType === 'gradient' && <input type="radio" name={`innerEyeColorType${id}`} className="radio " value="gradient" checked onChange={onInnerEyeColorTypeChange} />
                                        }
                                        {
                                            innerEyeColorType !== 'gradient' && <input type="radio" name={`innerEyeColorType${id}`} className="radio " value="gradient" onChange={onInnerEyeColorTypeChange} />
                                        }
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className="flex gap-4">
                            <label className="input input-bordered flex items-center gap-2">
                                Color 1
                                <input type="color" className="grow" value={innerEyeColor1} onChange={onInnerEyeColor1Change} />
                            </label>
                            {
                                innerEyeColorType === 'gradient' &&
                                <label className="input input-bordered flex items-center gap-2">
                                    Color 2
                                    <input type="color" className="grow" value={innerEyeColor2} onChange={onInnerEyeColor2Change} />
                                </label>
                            }


                        </div>
                    </div>
                </div>


                <div className="flex justify-around">
                    <button className="btn bg-sky-600 text-white px-6" onClick={saveHandler}>Save</button>
                    <button className="btn" onClick={closeHandler}>Cancel</button>
                </div>

            </div>


        </div>
    );
}


export default QrCodeEdit;