import { useEffect, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";

function App() {

    const [user, setUser] = useState(localStorage.getItem("username"));
    const [picture, setPicture] = useState(localStorage.getItem("picture"));
    const [token, setToken] = useState(localStorage.getItem("fastQRtoken"));
    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem("fastQRtoken");
        localStorage.removeItem("username");
        localStorage.removeItem("picture");
        setToken(null);
        setUser(null);
        setPicture(null);
        navigate('/login');

    }
    useEffect(() => {
        setUser(localStorage.getItem("username"));
        setPicture(localStorage.getItem("picture"));
        setToken(localStorage.getItem("fastQRtoken"));
    }, [token, user, picture])

    return (
        <>
            <div className="mx-5  lg:mx-0">
                {token ? (
                    <nav className="flex justify-between items-center gap-4 max-w-4xl mx-auto mt-10 mb-20">
                        <Link to="/" className="font-bold text-lg text-gray-800 flex items-center gap-4">
                            <img src="./logo.svg" alt="logo" className="w-10 md:w-16" />
                            FastQR
                        </Link>
                        <div className="flex flex-col justify-center items-center gap-3 ">
                            <div className=" flex justify-center items-center gap-2">
                                <img src={picture} alt="profile" className="w-6 md:w-10 rounded-3xl" />
                                <p>{user}</p>

                            </div>
                            <div>

                                <button onClick={logout} className="btn bg-sky-600 text-white px-4 py-1 rounded-md">Logout</button>
                            </div>
                        </div>
                    </nav>
                ) : (
                    <nav className="flex  items-center gap-4 max-w-4xl mx-auto mt-10 mb-20">
                        <Link to="/" className="font-bold text-lg text-gray-800 flex items-center gap-4">
                            <img src="./logo.svg" alt="logo" className=" w-10 md:w-16" />
                            FastQR
                        </Link>

                    </nav>
                )}
            </div>

            <div id="detail" className="mx-5 lg:mx-auto" >
                <Outlet context={[setToken]} />
            </div>
        </>



    )
}

export default App;