import React from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import axios from "./AxiosConfig";
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const [setToken] = useOutletContext();

    const handleLoginSuccess = async (response) => {
        const { credential } = response;
        console.log('Login success:', credential);
        try {
            const res = await axios.post('users/auth/google/', { "token": credential });
            localStorage.setItem('fastQRtoken', res.data.token);
            localStorage.setItem('username', res.data.username);
            localStorage.setItem('picture', res.data.picture);
            setToken(res.data.token);
            navigate('/');
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    const handleLoginFailure = (response) => {
        console.error('Login failed:', response);
    };

    return (
        <div className="flex justify-center items-center h-[50vh] ">
            <GoogleOAuthProvider clientId="417851086406-p7csf4gnpb2bqtdub0ltpm7firsknc5c.apps.googleusercontent.com">

                <GoogleLogin
                    clientId=""
                    buttonText="Login with Google"
                    onSuccess={handleLoginSuccess}
                    onFailure={handleLoginFailure}
                    cookiePolicy={'single_host_origin'}
                />
            </GoogleOAuthProvider>
        </div>
    );
};

export default Login;