import React, { useRef, useEffect } from "react";
import QRCodeStyling from "qr-code-styling";

function QrCodeDisplay({
  url,
  margin,
  patternType,
  patternColorType,
  patternColor1,
  patternColor2,
  outerEyePattern,
  outerEyeColorType,
  outerEyeColor1,
  outerEyeColor2,
  innerEyePattern,
  innerEyeColorType,
  innerEyeColor1,
  innerEyeColor2,
  backgroundColor,
  extension,
  size,
  logo,
  logoMargin
}) {

  const ref = useRef();
  const qrCode = useRef();


  useEffect(() => {
    const initialOptions = {
      width: size,
      height: size,
      type: 'canvas',
      data: url,
      image: logo ? URL.createObjectURL(logo) : null,
      margin: margin,
      dotsOptions: {
        color: patternColor1,
        type: patternType,
      },
      backgroundOptions: {
        color: backgroundColor,
      },
      cornersSquareOptions: {
        color: outerEyeColor1,
        type: outerEyePattern,
      },
      cornersDotOptions: {
        color: innerEyeColor1,
        type: innerEyePattern,
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: logoMargin
      }
    };

    if (patternColorType === 'gradient') {
      initialOptions.dotsOptions.gradient = {
        type: 'linear', // 'radial'
        rotation: 0,
        colorStops: [{ offset: 0, color: patternColor1 }, { offset: 1, color: patternColor2 }]
      };
    }

    if (innerEyeColorType === 'gradient') {
      initialOptions.cornersDotOptions.gradient = {
        type: 'linear', // 'radial'
        rotation: 0,
        colorStops: [{ offset: 0, color: innerEyeColor1 }, { offset: 1, color: innerEyeColor2 }]
      };
    }

    if (outerEyeColorType === 'gradient') {
      initialOptions.cornersSquareOptions.gradient = {
        type: 'linear', // 'radial'
        rotation: 0,
        colorStops: [{ offset: 0, color: outerEyeColor1 }, { offset: 1, color: outerEyeColor2 }]
      };
    }

    qrCode.current = new QRCodeStyling(initialOptions);
    qrCode.current.append(ref.current);

    return () => {
      qrCode.current = null;
    };
  }, []);

  useEffect(() => {
    if (qrCode.current) {

      const initialOptions = {
        width: size,
        height: size,
        type: 'canvas',
        data: url,
        image: logo ? URL.createObjectURL(logo) : null,

        margin: margin,
        dotsOptions: {
          color: patternColor1,
          type: patternType,
        },
        backgroundOptions: {
          color: backgroundColor,
        },
        cornersSquareOptions: {
          color: outerEyeColor1,
          type: outerEyePattern,
        },
        cornersDotOptions: {
          color: innerEyeColor1,
          type: innerEyePattern,
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: logoMargin
        }
      };

      if (patternColorType === 'gradient') {
        initialOptions.dotsOptions.gradient = {
          type: 'linear', // 'radial'
          rotation: 0,
          colorStops: [{ offset: 0, color: patternColor1 }, { offset: 1, color: patternColor2 }]
        };
      }

      if (innerEyeColorType === 'gradient') {
        initialOptions.cornersDotOptions.gradient = {
          type: 'linear', // 'radial'
          rotation: 0,
          colorStops: [{ offset: 0, color: innerEyeColor1 }, { offset: 1, color: innerEyeColor2 }]
        };
      }

      if (outerEyeColorType === 'gradient') {
        initialOptions.cornersSquareOptions.gradient = {
          type: 'linear', // 'radial'
          rotation: 0,
          colorStops: [{ offset: 0, color: outerEyeColor1 }, { offset: 1, color: outerEyeColor2 }]
        };
      }
      // destroy the previous instance
      qrCode.current = new QRCodeStyling(initialOptions);
      ref.current.innerHTML = '';
      qrCode.current.append(ref.current);
    }
  }, [
    url,
    margin,
    patternType,
    patternColorType,
    patternColor1,
    patternColor2,
    outerEyePattern,
    outerEyeColorType,
    outerEyeColor1,
    outerEyeColor2,
    innerEyePattern,
    innerEyeColorType,
    innerEyeColor1,
    innerEyeColor2,
    backgroundColor,
    extension,
    size,
    logo,
    logoMargin
  ]);

  return (
    <div ref={ref} className="inline-block"></div>
  );
}

export default QrCodeDisplay;