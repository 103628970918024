import QRCard from "./QRCard";
import { useState, useCallback, useEffect } from "react";
import { Shuffle, Eye } from 'lucide-react';
import axios from "./AxiosConfig";
import _ from 'lodash';


function Dashboard() {

  const [campaigns, setCampaigns] = useState([])
  const [name, setName] = useState('')
  const [shortCode, setShortCode] = useState('')
  const [isExistShortCode, setIsExistShortCode] = useState(false)
  const [targetUrl, setTargetUrl] = useState('')
  const [iosUrl, setIosUrl] = useState('')
  const [androidUrl, setAndroidUrl] = useState('')
  const [password, setPassword] = useState('')
  const [protectWithPassword, setProtectWithPassword] = useState(false)
  const [showPassword, setshowPassword] = useState(false);
  const [iosAndroidTargeting, setIosAndroidTargeting] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    setError(null);
    setName('');
    setShortCode('');
    setTargetUrl('');
    setIosUrl('');
    setAndroidUrl('');
    setPassword('');
    setProtectWithPassword(false);
    setIosAndroidTargeting(false);
  }, [campaigns])

  const validateShortCode = async (shortCode) => {
    const response = await axios.post("campaigns/check/", {

      "short_code": shortCode,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }

    }
    );
    console.log(response.data.exist)
    setIsExistShortCode(response.data.exist);
  }


  const debouncedChangeHandler = useCallback(
    _.debounce((newValue) => {
      validateShortCode(newValue);
    }, 400), // 400ms delay
    []
  );


  const onShortCodeChange = async (e) => {
    setShortCode(e.target.value);
    if (e.target.value === '') {
      setIsExistShortCode(false);
      return;
    }
    debouncedChangeHandler(e.target.value);

  }

  const onProtectWithPasswordChange = (e) => {
    setProtectWithPassword(e.target.checked);
    setPassword('');
  }

  const onIosAndroidTargetingChange = (e) => {
    setIosAndroidTargeting(e.target.checked);
    setAndroidUrl('');
    setIosUrl('');
  }

  const onNameChange = (e) => {
    setName(e.target.value)
  }



  const onTargetUrlChange = (e) => {
    setTargetUrl(e.target.value)
  }

  const onIosUrlChange = (e) => {
    setIosUrl(e.target.value)
  }

  const onAndroidUrlChange = (e) => {
    setAndroidUrl(e.target.value)
  }

  const onPasswordChange = (e) => {
    setPassword(e.target.value)
  }

  function seededRandom(seed) {
    let x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
  }
  const generateRandomShortCode = (e) => {
    e.preventDefault()
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    const seed = new Date().getTime();

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(seededRandom(seed + i) * charactersLength);
      result += characters.charAt(randomIndex);
    }
    setShortCode(result);
    debouncedChangeHandler(result);
  }

  const handleUpdateInfo = async (id, name, target_url, target_url_ios, target_url_android, password, iosAndroidTargeting, protectWithPassword) => {

    const data = {
      "name": name,
      "target_url": target_url,
      "target_url_ios": target_url_ios,
      "target_url_android": target_url_android,
      "password": password
    }

    if (!iosAndroidTargeting) {
      data.target_url_android = "";
      data.target_url_ios = "";
    }

    if (!protectWithPassword) {
      data.password = "";
    }
    try {
      const response = await axios.put(`campaigns/${id}/`, data, {
        headers: {
          'Content-Type': 'application/json',
        }
      });



      const updatedCampaigns = campaigns.map((campaign) => {
        if (campaign.id === id) {
          return response.data;
        }
        return campaign;
      });
      setCampaigns(updatedCampaigns);
      return true;
    } catch (error) {
      return false;
    }

  }

  const handleUpdateQR = async (id, margin, pattern_color1, pattern_color2, pattern_type, background_color, background_color_type, outer_eye_color1, outer_eye_color2, outer_eye_pattern, inner_eye_color1, inner_eye_color2, inner_eye_pattern, pattern_color_type, inner_eye_color_type, outer_eye_color_type) => {

    try {
      const response = await axios.put(`campaigns/${id}/qr/`, {
        "margin": parseInt(margin),
        "pattern_color1": pattern_color1,
        "pattern_color2": pattern_color2,
        "pattern_type": pattern_type,
        "background_color": background_color,
        "background_color_type": background_color_type,
        "outer_eye_color1": outer_eye_color1,
        "outer_eye_color2": outer_eye_color2,
        "outer_eye_pattern": outer_eye_pattern,
        "inner_eye_color1": inner_eye_color1,
        "inner_eye_color2": inner_eye_color2,
        "inner_eye_pattern": inner_eye_pattern,
        "pattern_color_type": pattern_color_type,
        "inner_eye_color_type": inner_eye_color_type,
        "outer_eye_color_type": outer_eye_color_type
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const updatedCampaigns = campaigns.map((campaign) => {
        if (campaign.id === id) {
          return response.data;
        }
        return campaign;
      }
      );
      setCampaigns(updatedCampaigns);
      return true
    } catch (error) {
      return false;
    }

  }


  const handleCloseCreationForm = (e) => {
    e.preventDefault()
    setError(null);
    setName('');
    setShortCode('');
    setTargetUrl('');
    setIosUrl('');
    setAndroidUrl('');
    setPassword('');
    setProtectWithPassword(false);
    setIosAndroidTargeting(false);
    setshowPassword(false)
    document.getElementById('create_qr_modal').close()
  }

  const handleCreate = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("campaigns/", {
        "name": name,
        "short_code": shortCode,
        "target_url": targetUrl,
        "ios_url": iosUrl,
        "android_url": androidUrl,
        "password": password,
        "protect_with_password": protectWithPassword,
        "ios_android_targeting": iosAndroidTargeting
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });


      setCampaigns([response.data, ...campaigns]);
      document.getElementById('create_qr_modal').close();


    } catch (error) {
      console.log(error)
      if (error.response.status === 400) {
        setError(error.response.data.error);
        return;
      } else {
        setError("Something went wrong");
        return;
      }

    }



  }

  const handleDelete = async (id) => {
    try {
      await axios.delete(`campaigns/${id}/`, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const updatedCampaigns = campaigns.filter((campaign) => campaign.id !== id);
      setCampaigns(updatedCampaigns);
    } catch (error) {
      console.log(error)
    }
  }

  const getCampaigns = async () => {
    const response = await axios.get("campaigns/", {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    setCampaigns(response.data.campaigns);
  }

  useEffect(() => {
    getCampaigns();
  }, [])


  return (
    <div className="md:max-w-xl lg:max-w-3xl sm:max-w-md space-y-5  mx-auto">

      <div className="flex justify-end">

        <button className="btn bg-gray-300" onClick={() => document.getElementById('create_qr_modal').showModal()}>Create QR Code</button>
      </div>
      <dialog id="create_qr_modal" className="modal" onCancel={event => event.preventDefault()}>
        <div className="modal-box max-w-2xl  ">
          <h3 className="font-bold text-lg mb-5">New QR Code</h3>
          <div >

            {error &&
              <div role="alert" className="alert alert-error mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>{error}</span>
              </div>
            }

            <form className=" space-y-3 " onSubmit={handleCreate} >



              <label className="input input-bordered flex items-center gap-2">
                Name
                <input type="text" className="grow" onChange={onNameChange} value={name} required />
              </label>

              <label className="input input-bordered flex items-center gap-2 ">
                <p >Short Code</p>
                <input type="text" className="grow  w-6" onChange={onShortCodeChange} value={shortCode} required />
                <Shuffle size={30} onClick={generateRandomShortCode} />
              </label>



              {isExistShortCode && <span className="text-red-500">Already exists</span>}

              <label className="input input-bordered flex items-center gap-2">
                Target URL
                <input type="url" className="grow" onChange={onTargetUrlChange} required value={targetUrl} placeholder="https://www.example.com" />
              </label>


              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text">IOS/Android Targeting</span>
                  <input type="checkbox" className="toggle" checked={iosAndroidTargeting} onChange={onIosAndroidTargetingChange} />
                </label>
              </div>

              {iosAndroidTargeting && (
                <div className="space-y-2">
                  <label className="input input-bordered flex items-center gap-2">
                    IOS URL
                    <input type="url" className="grow" onChange={onIosUrlChange} value={iosUrl} required placeholder="https://www.example.com" />
                  </label>
                  <label className="input input-bordered flex items-center gap-2">
                    Android URL
                    <input type="url" className="grow" onChange={onAndroidUrlChange} value={androidUrl} required placeholder="https://www.example.com" />
                  </label>
                </div>
              )}

              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text">Protect With Password</span>
                  <input type="checkbox" className="toggle" checked={protectWithPassword} onChange={onProtectWithPasswordChange} />
                </label>
              </div>

              {protectWithPassword && (
                <label className="input input-bordered flex items-center gap-2">
                  Password
                  <input type={showPassword ? "text" : "password"} className="grow" onChange={onPasswordChange} value={password} required />
                  <Eye onClick={() => setshowPassword((showPassword) => !showPassword)} />
                </label>
              )}

              <div className="flex justify-around mt-6">

                <button className="btn  bg-sky-600 text-white px-6" onSubmit={handleCreate} disabled={(isExistShortCode || shortCode.length === 0)} >Create</button>

                <button className="btn" onClick={handleCloseCreationForm}>Cancel</button>

              </div>
            </form>

          </div>
        </div>
      </dialog>

      <div className="grid grid-cols-1 gap-4">
        {campaigns.length === 0 &&

          <div className="text-center  mt-20 ">You don't have any QR codes</div>
        }
        {campaigns.map((campaign) => (
          <QRCard
            key={campaign.id}
            id={campaign.id}
            createdAt={campaign.created_at}
            campaignName={campaign.name}
            shortUrl={campaign.short_url}
            targetUrl={campaign.target_url}
            targetUrlAndroid={campaign.target_url_android}
            targetUrlIos={campaign.target_url_ios}
            password={campaign.password}
            margin={campaign.margin}
            patternColor1={campaign.pattern_color1}
            patternColor2={campaign.pattern_color2}
            patternType={campaign.pattern_type}
            backgroundColor={campaign.background_color}
            backgroundColorType={campaign.background_color_type}
            outerEyeColor1={campaign.outer_eye_color1}
            outerEyeColor2={campaign.outer_eye_color2}
            outerEyePattern={campaign.outer_eye_pattern}
            innerEyeColor1={campaign.inner_eye_color1}
            innerEyeColor2={campaign.inner_eye_color2}
            innerEyePattern={campaign.inner_eye_pattern}
            patternColorType={campaign.pattern_color_type}
            innerEyeColorType={campaign.inner_eye_color_type}
            outerEyeColorType={campaign.outer_eye_color_type}
            scans={campaign.scans}

            onUpdateInfo={handleUpdateInfo}
            onUpdateQR={handleUpdateQR}
            onDelete={handleDelete}
          />
        ))}


      </div>
    </div>
  );
}

export default Dashboard;